import getLogRocket from './logRocket'

const loggedEvent: Record<string, boolean> = {}

function log(evt: string) {
  const { logRocket } = getLogRocket()
  // MixPanel.track(evt)
  logRocket?.track(evt)
}

function logOnce(evt: string) {
  if (evt && !loggedEvent[evt]) {
    loggedEvent[evt] = true
    log(evt)
  }
}

function forget(evt: string) {
  delete loggedEvent[evt]
}

export function logProductEvent(
  action:
    | 'view_abd'
    | 'abd_success'
    | 'abd_error'
    | 'version_br1'
    | 'version_x144'
    | 'version_z144'
    | 'version_k144'
    | 'version_f144'
    | 'version_vta1'
    | 'version_v146'
    | 'version_vta3'
    | 'version_vta31'
    | 'version_vta2'
    | 'version_c145'
    | 'version_v13'
    | 'version_v141'
    | 'version_v142'
    | 'version_vx141'
    | 'version_v144'
    | 'version_e144'
    | 'version_vcdn3'
    | 'version_acdn3'
    | 'version_vna2'
    | 'version_vli1'
    | 'version_vta4'
    | 'version_v145'
    | 'version_vap1'
    | 'version_gp01a'
    | 'version_gp01b'
    | 'version_gp02'
    | 'version_a144'
    | 'version_c144'
    | 'version_a145'
    | 'version_b144'
    | 'version_v146'
    | 'version_ba10'
    | 'version_ba11'
    | 'version_ba23'
    | 'version_bb23'
    | 'version_p144'
    | 'version_d144'
    | 'version_g144'
    | 'version_r144'
    | 'version_m144'
    | 'version_ab144'
    | 'version_i144'
    | 'version_o144'
    | 'version_l144'
    | 'version_vpo1'
    | 'version_usp1'
    | 'version_usp2'
    | 'version_usp3'
    | 'version_usp4'
    | 'version_vlz1'
    | 'version_vpl1'
    | 'version_y144'
    | 'version_h144' // trigger payment
    | 'version_vta1'
    | 'clearance_popup'
    | 'view_product'
    | 'order_summary'
    | 'add_to_cart'
    | 'remove_from_cart'
    | 'open_cart'
    | 'checkout_credit_card'
    | 'checkout_paypal_cart'
    | 'checkout_paypal_buy_now'
    | 'paid_credit_card'
    | 'paid_paypal'
    | 'payment_success'
    | 'payment_failure'
    | 'payment_success_paypal_cart'
    | 'payment_success_paypal_buy_now'
    | 'payment_success_creadit_card'
    | 'paypal_pay_now'
    | 'paypal_continue'
    | 'upsell_select_now'
    | 'amazon_pay_cart'
    | 'amazon_pay_checkout'
    | 'low_battery'
    | 'reset_version'
    | 'aftersale_view_stripe'
    | 'aftersale_view_paypal'
    | 'aftersale_add_to_cart'
    | 'aftersale_payment_success'
    | 'aftersale_payment_success_stripe'
    | 'aftersale_payment_success_paypal'
    | 'aftersale_checkout_credit_card'
    | 'aftersale_checkout_paypal_cart'
    | 'aftersale_checkout_paypal_buy_now'
    | 'tax_zero'
    | 'tax_zero_paypal'
    | 'tax_zero_stripe'
    | 'add_payment_info'
    | 'fill_card_completed'
    | 'version_apv1'
    | 'version_apv2'
    | 'version_apv21'
    | 'version_apv3'
    | 'version_ac144'
    | 'version_apv5'
    | 'version_apv51'
    | 'version_b25v24'
    | 'version_a25v26'
    | 'version_apv6'
    | 'version_f25v51'
    | 'version_f25v26'
    | 'apple_pay_add_to_cart'
    | 'after_sale_apple_pay_add_to_cart'
    | 'apple_pay_payment_success'
    | 'after_sale_apple_pay_payment_success'
    | 'checkout_apple_pay_cart'
    | 'apple_pay_add_payment_info'
    | 'apple_pay_active'
    | 'apple_pay_inactive'
    | 'view_apple_pay_active'
    | 'view_apple_pay_inactive'
    | 'checkout_apple_pay_buynow'
    | 'payment_success_apple_pay_buynow'
) {
  const eventName = {
    view_abd: 'VIEW_ADB',
    abd_success: 'ABD_SUCCESS',
    abd_error: 'ADB_ERROR',
    version_br1: 'VERSION_BR1',
    version_x144: 'VERSION_X144',
    version_z144: 'VERSION_Z144',
    version_k144: 'VERSION_K144',
    version_y144: 'VERSION_Y144',
    version_h144: 'VERSION_H144',
    version_vta1: 'VERSION_VTA1',
    version_v146: 'VERSION_V146',
    version_vta3: 'VERSION_VTA3',
    version_vta31: 'VERSION_VTA31',
    version_vta2: 'VERSION_VTA2',
    version_c145: 'VERSION_C145',
    version_f144: 'VERSION_F144',
    version_v13: 'VERSION_V13',
    version_v141: 'VERSION_V141',
    version_v142: 'VERSION_V142',
    version_vcdn3: 'VERSION_VCDN3',
    version_acdn3: 'VERSION_ACDN3',
    version_vna2: 'VERSION_VNA2',
    version_vli1: 'VERSION_VLI1',
    version_vta4: 'VERSION_VTA4', //trigger payment
    version_v145: 'VERSION_V145',
    version_vap1: 'VERSION_VAP1',
    version_vx141: 'VERSION_VX141',
    version_v144: 'VERSION_V144',
    version_e144: 'VERSION_E144',
    version_ba10: 'VERSION_BA10',
    version_ba11: 'VERSION_BA11',
    version_ba23: 'VERSION_BA23',
    version_bb23: 'VERSION_BB23',
    version_p144: 'VERSION_P144',
    version_d144: 'VERSION_D144',
    version_o144: 'VERSION_G144',
    version_l144: 'VERSION_L144',
    version_r144: 'VERSION_R144',
    version_i144: 'VERSION_I144',
    version_m144: 'VERSION_M144',
    version_ab144: 'VERSION_AB144',
    version_vlz1: 'VERSION_VLZ1',
    version_g144: 'VERSION_O144',
    version_vpo1: 'VERSION_VPO1',
    version_usp1: 'VERSION_USP1',
    version_usp2: 'VERSION_USP2',
    version_usp3: 'VERSION_USP3',
    version_usp4: 'VERSION_USP4',
    version_a144: 'VERSION_A144',
    version_c144: 'VERSION_C144',
    version_a145: 'VERSION_A145',
    version_vpl1: 'VERSION_VPL1',
    version_b144: 'VERSION_B144',
    version_gp01a: 'VERSION_GP01A',
    version_gp01b: 'VERSION_GP01B',
    version_gp02: 'VERSION_GP02',
    clearance_popup: 'CLEARANCE_POPUP',
    view_product: 'VIEW_PRODUCT',
    order_summary: 'ORDER_SUMMARY',
    add_to_cart: 'ADD_TO_CART',
    remove_from_cart: 'REMOVE_FROM_CART',
    open_cart: 'OPEN_CART',
    checkout_credit_card: 'CHECKOUT_CREDIT_CARD',
    checkout_paypal_cart: 'CHECKOUT_PAYPAL_CART',
    checkout_apple_pay_cart: 'CHECKOUT_APPLE_PAY_CART',
    checkout_paypal_buy_now: 'CHECKOUT_PAYPAL_BUY_NOW',
    paid_credit_card: 'PAID_CREDIT_CARD',
    paid_paypal: 'PAID_PAYPAL',
    payment_success: 'PAYMENT_SUCCESS',
    payment_failure: 'PAYMENT_FAILURE',
    payment_success_paypal_buy_now: 'PAYMENT_SUCCESS_PAYPAL_BUY_NOW',
    payment_success_paypal_cart: 'PAYMENT_SUCCESS_PAYPAL_CART',
    payment_success_creadit_card: 'PAYMENT_SUCCESS_CREDIT_CARD',
    paypal_pay_now: 'PAYPAL_PAY_NOW',
    paypal_continue: 'PAYPAL_CONTINUE',
    upsell_select_now: 'UPSELL_SELECT_NOW',
    amazon_pay_cart: 'AMAZON_PAY_CART',
    amazon_pay_checkout: 'AMAZON_PAY_CHECKOUT',
    low_battery: 'LOW_BATTERY',
    reset_version: 'RESET_VERSION',
    aftersale_view_stripe: 'AFTERSALE_VIEW_STRIPE',
    aftersale_view_paypal: 'AFTERSALE_VIEW_PAYPAL',
    aftersale_add_to_cart: 'AFTERSALE_ADD_TO_CART',
    aftersale_payment_success: 'AFTERSALE_PAYMENT_SUCCESS',
    aftersale_payment_success_stripe: 'AFTERSALE_PAYMENT_SUCCESS_STRIPE',
    aftersale_payment_success_paypal: 'AFTERSALE_PAYMENT_SUCCESS_PAYPAL',
    aftersale_checkout_credit_card: 'AFTERSALE_CHECKOUT_CREDIT_CARD',
    aftersale_checkout_paypal_cart: 'AFTERSALE_CHECKOUT_PAYPAL_CART',
    aftersale_checkout_paypal_buy_now: 'AFTERSALE_CHECKOUT_PAYPAL_BUY_NOW',
    tax_zero: 'TAX_ZERO',
    tax_zero_paypal: 'TAX_ZERO_PAYPAL',
    tax_zero_stripe: 'TAX_ZERO_STRIPE',
    add_payment_info: 'ADD_PAYMENT_INFO',
    fill_card_completed: 'FILL_CARD_COMPLETED',
    apple_pay_active: 'APPLE_PAY_ACTIVE',
    apple_pay_inactive: 'APPLE_PAY_INACTIVE',
    version_apv1: 'VERSION_APV1',
    version_apv2: 'VERSION_APV2',
    version_apv3: 'VERSION_APV3',
    version_ac144: 'VERSION_AC144',
    version_apv5: 'VERSION_APV5',
    version_apv51: 'VERSION_APV51',
    version_b25v24: 'VERSION_B25V24',
    version_a25v26: 'VERSION_25V26',
    version_apv6: 'VERSION_APV6',
    version_apv21: 'VERSION_APV21',
    version_f25v51: 'VERSION_F25V51',
    version_f25v26: 'VERSION_F25V26',
    apple_pay_add_to_cart: 'APPLE_PAY_ADD_TO_CART',
    after_sale_apple_pay_add_to_cart: 'AFTER_SALE_APPLE_PAY_ADD_TO_CART',
    apple_pay_payment_success: 'APPLE_PAY_PAYMENT_SUCCESS',
    after_sale_apple_pay_payment_success: 'AFTER_SALE_APPLE_PAY_PAYMENT_SUCCESS',
    apple_pay_add_payment_info: 'APPLE_PAY_ADD_PAYMENT_INFO',
    view_apple_pay_active: 'VIEW_APPLE_PAY_ACTIVE',
    view_apple_pay_inactive: 'VIEW_APPLE_PAY_INACTIVE',
    checkout_apple_pay_buynow: 'CHECKOUT_APPLE_PAY_BUYNOW',
    payment_success_apple_pay_buynow: 'PAYMENT_SUCCESS_APPLE_PAY_BUYNOW'
  }[action]
  eventName && log(eventName)
}

export function logViewProduct(route: any) {
  logOnce('VIEW_PRODUCT')

  const query = route.query
  let eventName = 'DIRECT_VIEW_PRODUCT'
  if (query.p && query.ref === 'sms_lead') {
    eventName = `LEAD_CONVERTER_VIEW_PRODUCT`
  }
  if (query['utm_campaign']) {
    eventName = 'CLICK_FB_VIEW_PRODUCT'
  }
  logOnce(eventName)

  query.p && logOnce(`APPLY_${query.p}`)
}

export function logSMSImpression(action: 'open' | 'typing' | 'sub' | 'close' | 'header') {
  const eventName = {
    open: 'LEAD_CONVERTER_IMPRESSION',
    close: 'LEAD_CONVERTER_CLOSE',
    sub: 'LEAD_CONVERTER_SUBSCRIBE',
    typing: 'LEAD_CONVERTER_FILL_PHONE',
    header: 'OPEN_HEADER_OFFER'
  }[action]
  eventName && logOnce(eventName)
}
export function logPageVersion(page: string) {
  logOnce(`PAGEVERSION_${page}`)
}

export function logProductPage(
  action: 'variant' | 'quantity' | 'buy' | 'aftersale_buy' | 'addCart'
) {
  const eventName = {
    variant: 'PRODUCT_CHOOSE_VARIANT',
    quantity: 'PRODUCT_CHOOSE_QUANTITY',
    buy: 'PRODUCT_BUY_IT_NOW',
    aftersale_buy: 'AFTERSALE_PRODUCT_BUY_IT_NOW',
    addCart: 'ADD_TO_CART'
  }[action]
  eventName && logOnce(eventName)
}

export function logCart(action: 'remove' | 'quantity' | 'buy' | 'aftersale_buy' | 'close') {
  const eventName = {
    remove: 'PRODUCT_CART_REMOVE_LINE',
    quantity: 'PRODUCT_CART_CHOOSE_QUANTITY',
    buy: 'PRODUCT_CART_PROCESS_CHECKOUT',
    aftersale_buy: 'AFTERSALE_PRODUCT_CART_PROCESS_CHECKOUT',
    close: 'PRODUCT_CART_CLOSE'
  }[action]
  eventName && logOnce(eventName)
}

export function logCheckoutAction(
  action: 'email' | 'phone' | 'shipping' | 'card' | 'order_token_fail'
) {
  const eventName = {
    email: 'CHECKOUT_FILL_EMAIL',
    phone: 'CHECKOUT_FILL_PHONE_NUMBER',
    shipping: 'CHECKOUT_FILL_SHIPPING_INFORMATION',
    card: 'CHECKOUT_FILL_CARD_INFORMATION',
    order_token_fail: 'TOKEN_ORDER_NOT_FOUND'
  }[action]
  eventName && logOnce(eventName)
}

export function logPaypal(action: 'initCard' | 'initCheckout' | 'success' | 'fail' | 'cancel') {
  const eventName = {
    initCheckout: 'PAYPAL_EXPRESS_CHECKOUT',
    initCard: 'PAYPAL_EXPRESS_CART',
    success: 'PAYPAL_SUCCESS',
    fail: 'PAYPAL_FAILED',
    cancel: 'PAYPAL_CANCEL'
  }[action]
  eventName && log?.(eventName)
  action === 'success' && log?.('PAYMENT_SUCCESS')
}

export function logMakePayment(action: 'success' | 'fail') {
  const eventName = {
    success: 'MAKE_PAYMENT_SUCCESS',
    fail: 'MAKE_PAYMENT_FAILED'
  }[action]
  eventName && log?.(eventName)
  action === 'success' && log?.('PAYMENT_SUCCESS')
  action === 'fail' && forget?.('CHECKOUT_FILL_CARD_INFORMATION')
}

export function logThankyou(action: 'visit' | 'confirm' | 'aftersale_scroll') {
  const eventName = {
    visit: 'VISIT_THANKYOU',
    confirm: 'CONFIRM_BILLING_ADDRESS',
    aftersale_scroll: 'AFTERSALE_SCROLL'
  }[action]
  logOnce(eventName)
}

export function logDuplicatedOrder(action: 'showNoti') {
  const eventName = {
    showNoti: 'SEE_DUPLICATED_NOTIFICATION'
  }[action]
  log?.(eventName)
}

export function logShopHelp(action: 'BADGE' | 'OPEN' | 'STATIC') {
  let eventName = ''
  switch (action) {
    case 'BADGE':
      eventName = 'CLICK_SHOPHELP_BADGE'
      break
    case 'OPEN':
      eventName = 'CLICK_OPEN_SHOPHELP'
      break
    case 'STATIC':
      eventName = 'CLICK_SHOPHELP_STATIC'
      break
  }
  log?.(eventName)
}
